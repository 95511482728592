<template>
    <v-skeleton-loader :loading="loadingdata" type="table">
      <v-data-table :headers="headers" :items="items" :search="search" class="elevation-1">
        <template v-slot:item.action="{ item }">
         <v-btn small v-if="item.doc_id!== undefined && item.extension == 'video'" :href="item.file" target="_blank" color="primary">{{ 'View Video'}}</v-btn>
         <v-btn small v-else-if="item.doc_id!== undefined" v-on:click="handleDownload(item)" color="primary">{{ 'View'}}</v-btn>
         <v-icon
            small
            class="mr-2"
            v-if="item.gendoc_id!== undefined && visiblestatus"
            v-on:click="handleDownload(item.doc_files[0])"
          >mdi-briefcase-download</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.company_id!== undefined"
            v-on:click="handleClick(item.company_id)"
          >edit</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.id!== undefined"
            v-on:click="handleClick(item.id)"
          >edit</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.gendoc_id!== undefined && !visiblestatus"
            v-on:click="handleClick(item.gendoc_id)"
          >edit</v-icon>  
          <v-icon
            small
            class="mr-2"
            v-if="item.perdoc_id!== undefined"
            v-on:click="handleClick(item.perdoc_id)"
          >edit</v-icon>           
          <v-icon
            small
            class="mr-2"
            v-else-if="item.module_id!== undefined"
            v-on:click="handleClick(item.module_id)"
          >edit</v-icon>
          <v-icon
            small
            class="mr-2"
            v-else-if="item.author_id!== undefined"
            v-on:click="handleClick(item.author_id)"
          >edit</v-icon>
           <v-icon
            small
            class="mr-2"
            v-else-if="item.tutor_product_id!== undefined"
            v-on:click="handleClick(item.tutor_product_id)"
          >edit</v-icon>
          <v-icon
            small
            class="mr-2"
            v-else-if="item.team_id!== undefined"
            v-on:click="handleClick(item.team_id)"
          >edit</v-icon>
          <v-icon
            small
            class="mr-2"
            v-else-if="item.course_id!== undefined && item.cert_id == undefined && item.cd_id == undefined"
            v-on:click="handleClick(item.course_id)"
          >edit</v-icon>   
          <v-icon
            small
            class="mr-2"
            v-else-if="item.page_id!== undefined"
            v-on:click="handleClick(item.page_id)"
          >edit</v-icon>
         <v-icon
            small
            class="mr-2"
            v-if="item.cert_id!== undefined"
            v-on:click="handlePreview(item.cert_id)"
          >mdi-eye</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.cert_id!== undefined && item.status == 'active'"
            v-on:click="handleClick(item)"
          >edit</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.cert_id!== undefined"
            v-on:click="handleDelete(item.cert_id)"
          >delete</v-icon>                                  
          <v-icon
            small
            class="mr-2"
            v-if="item.author_id!== undefined"
            v-on:click="handleDelete(item.author_id)"
          >delete</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.tutor_product_id!== undefined"
            v-on:click="handleDelete(item.tutor_product_id)"
          >delete</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.team_id!== undefined && item.tutor_product_id == undefined"
            v-on:click="handleDelete(item.team_id)"
          >delete</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.module_id!== undefined"
            v-on:click="handleDelete(item.module_id)"
          >delete</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.id!== undefined"
            v-on:click="handleDelete(item.id)"
          >delete</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.gendoc_id!== undefined"
            v-on:click="handleDelete(item.gendoc_id)"
          >delete</v-icon> 
          <v-icon
            small
            class="mr-2"
            v-if="item.perdoc_id!== undefined"
            v-on:click="handleDelete(item.perdoc_id)"
          >delete</v-icon>           

          <v-icon
            small
            class="mr-2"
            v-if="item.page_id!== undefined"
            v-on:click="handleDelete(item.page_id)"
          >delete</v-icon>
          <v-icon
            small
            class="mr-2"
            v-if="item.cd_id !== undefined"
            v-on:click="handleDelete(item.cd_id)"
          >delete</v-icon>          
          <v-icon
            small
            class="mr-2"
            v-if="item.course_id!== undefined && item.cert_id == undefined && item.module_id == undefined && item.cd_id == undefined"
            v-on:click="handleDelete(item.course_id)"
          >delete</v-icon>
        </template>
        <template v-slot:item.status="{ item }">
          {{ (item.status == 'active'?'Active' : 'Inactive') }}
        </template>
        <template v-slot:item.language="{ item }">
          <div class="my-2" v-if="item.language == 'en' && item.page_id != undefined">
            English
          </div>
          <div class="my-2" v-else-if="item.language == 'de' && item.page_id != undefined">
            German
          </div>          
        </template>
          <template v-slot:top>
          <v-card-title>
            {{ title }}
            <v-spacer></v-spacer>
            <v-btn v-if="title==='General documents' && visiblestatus" v-on:click="addDocuments()"  color="primary">{{ 'Add Documents'}}</v-btn>
            <v-btn v-if="title==='Certifications' && visiblestatus" v-on:click="addCertifications()"  color="primary">{{ 'Add Certifications'}}</v-btn>
          </v-card-title>
          <v-toolbar flat color="white">
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
          </v-toolbar>      
          </template>        
        <template v-slot:item.pdf="{ item }">
          <div class="my-2">
            <v-btn small color="primary" :href="item.invoice_pdf" target="_blank">Download PDF</v-btn>
          </div>
        </template>
        <template v-slot:item.company_status="{ item }">
          <v-chip class="ma-2" :color="item.status == 'active' ? 'success' : item.status == 'inactive' ? 'danger' : 'primary'  ">{{ item.status }}</v-chip>
        </template>

      </v-data-table>
    </v-skeleton-loader>
</template>

<style lang="scss">
.v-data-footer {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.v-application--is-ltr .v-data-footer__select {
  margin-left: 16px !important;
}
</style>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DataTable",
  computed: {
    ...mapGetters(["loadingdata"])
  },  
  methods: {
    handleClick(id) {
      this.$emit("editing", id);
    },
    handleDownload(data) {
      this.$emit("editing", data);
    },
    handlePreview(data) {
      this.$emit('preview',data)
    },
    handleDelete(id) {
      this.$emit("deleting", id);
    },
    addDocuments(){
      this.$emit("openDocumentbox")
    },    
    addCertifications(){
      this.$emit('openCertificationbox')
    }
  },
  data: function() {
    return {
      search: ''
    }
  },
  props: {
    selected: Array,
    headers: Array,
    items: Array,
    title: String,
    visiblestatus: Boolean,
  }
};
</script>

