export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const CHECK_AUTH = 'checkAuth'
export const FETCH_MODULES = 'fetchModules'
export const FETCH_INDIVIDUAL_MODULE = 'fetchIndividualModule'
export const ADD_MODULE = 'addModule'
export const UPDATE_MODULE = 'updateModule'
export const UPLOAD_MODULE = 'uploadModule'
export const DELETE_MODULE = 'deleteModule'
export const MODULES_RESET_STATE = 'resetModulesState'
export const FETCH_COURSES = 'fetchCourses'
export const FETCH_INDIVIDUAL_COURSE = 'fetchIndividualCourse'
export const ADD_COURSE = 'addCourse'
export const UPDATE_COURSE = 'updateCourse'
export const DELETE_COURSE = 'deleteCourse'
export const COURSE_RESET_STATE = 'resetCourseState'
export const FETCH_COMPANIES = 'fetchCompanies'
export const FETCH_USERS = 'fetchUsers'
export const FETCH_AUTHORS = 'fetchAuthors'
export const FETCH_PAGES    =   'fetchPages'
export const FETCH_LANGUAGES    =   'fetchLanguages'
export const FETCH_DOCUMENTS    =   'fetchDocuments'
export const DOWNLOAD_GENERAL_DOCUMENT  =   'downloadGeneralDocument'
export const DOWNLOAD_PERSONAL_DOCUMENT  =   'downloadPersonalDocument'
export const FETCH_INDIVIDUAL_AUTHOR = 'fetchIndividualAuthor'
export const FETCH_INDIVIDUAL_PAGE  =   'fetchIndividualPage'
export const FETCH_ALL_INVOICES = 'fetchAllInvoices'
export const FETCH_INDIVIDUAL_COMPANY = 'fetchIndividualCompany'
export const FETCH_GENERAL_DOCUMENTS    =   'fetchGeneralDocuments'
export const FETCH_SECTION_HEADER_TAGS   =   'fetchSectionHeaderTags'
export const FETCH_PERSONAL_DOCUMENTS    =   'fetchPersonalDocuments'
export const FETCH_COURSE_DOCUMENTS    =   'fetchCourseDocuments'
export const FETCH_INDIVIDUAL_PERSONAL_DOCUMENT    =   'fetchIndividualPersonalDocument'
export const FETCH_INDIVIDUAL_GENERAL_DOCUMENT    =   'fetchIndividualGeneralDocument'
export const ADD_GENERAL_DOCUMENT    =   'addGeneralDocument'
export const UPDATE_GENERAL_DOCUMENT    =   'updateGeneralDocument'
export const ADD_PERSONAL_DOCUMENT    =   'addPersonalDocument'
export const UPDATE_PERSONAL_DOCUMENT    =   'updatePersonalDocument'
export const DELETE_GENERAL_DOCUMENT    =   'deleteGeneralDocument'
export const DELETE_PERSONAL_DOCUMENT    =   'deletePersonalDocument'
export const ADD_DOCUMENT   =   'addDocument'
export const ADD_COMPANY = 'addCompany'
export const ADD_AUTHOR = 'addAuthor'
export const ADD_PAGE = 'addPage'
export const UPDATE_USER = 'updateUser'
export const UPDATE_AUTHOR = 'updateAuthor'
export const UPDATE_PAGE    =   'updatePage'
export const UPDATE_COMPANY = 'updateCompany'
export const DELETE_AUTHOR = 'deleteAuthor'
export const DELETE_PAGE = 'deletePage'
export const DELETE_DOCUMENT = 'deleteDocument'
export const STRIPE_RESET_STATE = 'resetStripeState'
export const AUTHOR_RESET_STATE = 'resetAuthorState'
export const PAGES_RESET_STATE  =   'resetPagesState'
export const LANGUAGES_RESET_STATE  =   'resetLanguagesState'
export const COMPANIES_RESET_STATE = 'resetCompaniesState'
export const DOCUMENTS_RESET_STATE = 'resetDocumentsState'
export const FETCH_PRODUCTS_ID  =   'fetchProductsID'
export const FETCH_PLANS  =   'fetchPlans'
export const FETCH_VOUCHER_PRODUCTS_ID  =   'fetchVoucherProductsID'
export const RESET_SECTION_HEADERS_STATE  =   'resetSectionHeaderState'
export const ADD_SECTION_HEADER  =   'createSectionHeader'
export const FETCH_VIDEO_TUTORIAL_DATA = 'fetchVideoTutorialData'
export const FETCH_INDIVIDUAL_VIDEO_TUTORIAL_DATA = 'fetchIndividualVideoTutorialData'
export const CREATE_VIDEO_TUTORIAL_DATA = 'createVideoTutorialData'
export const UPDATE_VIDEO_TUTORIAL_DATA = 'updateVideoTutorialData'
export const VIDEO_TUTORIAL_STATE_RESET  =   'VideoTutorialStatereset'
export const DELETE_VIDEO_TUTORIAL = 'deleteVideoTutorial'
export const FETCH_CERTIFICATE_TEMPLATE = 'fetchCertificateTemplate'
export const FETCH_INDIVIDUAL_CERTIFICATE_TEMPLATE = 'fetchIndividualCertificateTemplate'
export const CREATE_CERTIFICATE_TEMPLATE = 'createCertificateTempate'
export const UPDATE_CERTIFICATE_TEMPLATE = 'updateCertificateTemplate'
export const DELETE_CERTIFICATE_TEMPLATE = 'deleteCertificateTemplate'
export const CERTIFICATE_RESET_STATE  =   'resetCertificateTemplate'
export const PREVIEW_CERTIFICATE_TEMPLATE = 'previewCertificateTempalte'
export const FETCH_TEAMS = 'fetchTeams'
export const ADD_TEAM = 'addTeam'
export const UPDATE_TEAM = 'updateTeam'
export const DELETE_TEAM = 'deleteTeam'
export const FETCH_INDIVIDUAL_TEAM = 'fetchIndividualTeam'
export const TEAM_RESET_STATE = 'resetTeamState'

export const FETCH_TUTOR_PRODUCTS_ID = 'fetchTutorProductsId'
export const FETCH_TUTOR_PLANS = 'fetchTutorPlans'
export const FETCH_TUTOR_VOUCHER_PRODUCTS_ID = 'fetchTutorVoucherProductsId'

export const FETCH_TUTOR_PRODUCTS = 'fetchTutorProducts'
export const DELETE_TUTOR_PRODUCT = 'deleteTutorProduct'
export const ADD_TUTOR_PRODUCT = 'addTutorProduct'
export const UPDATE_TUTOR_PRODUCT = 'updateTutorProduct'
export const FETCH_INDIVIDUAL_TUTOR_PRODUCT = 'fetchIndividualTutorProduct'
export const TUTOR_PRODUCT_RESET_STATE = 'TutorProductResetState'

