import Vue from "vue";
import axios from "axios";
import store from "@/store";
import VueAxios from "vue-axios";
import JwtService from "@/common/jwt.service";
import { SET_UPLOAD_PROGRESS } from "../store/mutations.type";

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  },

  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params)
  },

  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`)
  },

  post(resource, params) {
    return Vue.axios.post(`${resource}`, params)
  },

  uploadFile(resource, params) {
    return Vue.axios.post(`${resource}`, params,  
    {
      onUploadProgress:  progressEvent => store.commit(SET_UPLOAD_PROGRESS,parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 )))
    })
  },  

  download(resource, slug) {
    return Vue.axios.get(`${resource}/${slug}`, {responseType: 'blob'})
  }, 

  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params)
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params)
  },

  delete(resource, slug, params) {
    return Vue.axios.delete(`${resource}/${slug}`, params)
  }
};

export default ApiService;

export const ModulesService = {
  query(type, params) {
    return ApiService.query("modules/" +type, {
      params: params
    });
  },
  get(slug){
    return ApiService.get("modules", slug); 
  },
  create(params) {
    return ApiService.post("modules" ,params);
  }, 
  uploadModule(params) {
    return ApiService.uploadFile("modules/upload-file" ,params)
  },     
  update(params) {
    return ApiService.post("modules/"+params.get('module_id'), params);
  },   
  delete(params) {
    return ApiService.delete("modules",params);
  }  
};

export const AuthorsService = {
  get(slug){
    return ApiService.get("authors", slug); 
  },
  create(params) {
    return ApiService.post("authors" ,params);
  }, 
  update(params) {
    return ApiService.post("authors/"+params.get('author_id'), params);
  },  
  delete(params) {
    return ApiService.delete("authors",params);
  }     
};

export const TeamService = {
  get(slug){
    return ApiService.get("teams", slug); 
  },
  create(params) {
    return ApiService.post("teams" ,params);
  }, 
  update(params) {
    return ApiService.post("teams/"+params.get('team_id'), params);
  },  
  delete(params) {
    return ApiService.delete("teams",params);
  }     
};

export const TutorProductService = {
  get(slug){
    return ApiService.get("tutor-products", slug); 
  },
  create(params) {
    return ApiService.post("tutor-products" ,params);
  }, 
  update(params) {
    return ApiService.post("tutor-products/"+params.get('tutor_product_id'), params);
  },  
  delete(params) {
    return ApiService.delete("tutor-products",params);
  }     
};

export const CompaniesService = {
  get(slug){
    return ApiService.get("companies",slug)
  },
  create(params) {
    return ApiService.post("companies" ,params)
  },   
  update(params) {
    return ApiService.post("companies/"+params.get('company_id'), params)
  },     
}

export const StripeService = {
  get(slug){
    return ApiService.get("stripe",slug)
  },   
}

export const CoursesService = {
  get(slug){
    return ApiService.get('courses',slug)
  },
  create(params) {
    return ApiService.post("courses" ,params)
  },   
  delete(params) {
    return ApiService.delete("courses",params);
  },
  update(params) {
    return ApiService.post("courses/"+params.get('course_id'), params)
  }   
}

export const PagesService = {
  get(slug){
    return ApiService.get("pages",slug)
  },   
  create(params) {
    return ApiService.post("pages" ,params)
  },     
  update(params) {
    return ApiService.post("pages/"+params.get('page_id'), params)
  },
  delete(params) {
    return ApiService.delete("pages",params);
  },      
}

export const LanguageService = {
  get(slug){
    return ApiService.get("languages",slug)
  }  
}

export const DocumentsService = {
  get(slug){
    return ApiService.get("course-documents",slug)
  },
  uploadDocument(params) {
    return ApiService.uploadFile("course-documents" ,params)
  },    
  delete(params) {
    return ApiService.delete("course-documents",params);
  },
  downloadFile(slug){
    return ApiService.download("course-documents",slug)
  }   
}

export const GeneralDocumentsService =  {
  get(slug){
    return ApiService.get("general-documents",slug)
  },  
  create(params) {
    return ApiService.post("general-documents" ,params)
  }, 
  update(params) {
    return ApiService.post("general-documents/"+params.get('gendoc_id'), params)
  },  
  downloadFile(slug){
    return ApiService.download("general-documents",slug)
  },   
  delete(params) {
    return ApiService.delete("general-documents",params);
  },
}

export const PersonalDocumentsService =  {
  get(slug){
    return ApiService.get("personal-documents",slug)
  },  
  create(params) {
    return ApiService.post("personal-documents" ,params)
  }, 
  update(params) {
    return ApiService.post("personal-documents/"+params.get('perdoc_id'), params)
  },  
  downloadFile(slug){
    return ApiService.download("personal-documents",slug)
  },    
  delete(params) {
    return ApiService.delete("personal-documents",params);
  },
}

export const SectionHeaderService =  {
  get(slug){
    return ApiService.get("section-headers",slug)
  },  
  create(params) {
    return ApiService.post("section-headers" ,params)
  },     
  update(params) {
    return ApiService.post("section-headers/"+params.get('page_id'), params)
  },
  delete(params) {
    return ApiService.delete("section-headers",params);
  },  
}

  export const TutorialVideoService =  {
    get(slug){
      return ApiService.get("tutorial-videos",slug)
    },  
    create(params) {
      return ApiService.post("tutorial-videos" ,params)
    },     
    update(params) {
      return ApiService.post("tutorial-videos/"+params.id, params)
    },
    delete(params) {
      return ApiService.delete("tutorial-videos",params);
    },   
    
  }

  export const CertificateTemplateService =  {
    get(slug){
      return ApiService.get("certificate-templates",slug)
    },  
    create(params) {
      return ApiService.post("certificate-templates/"+params.slug ,params.data)
    },     
    update(params) {
      return ApiService.post("certificate-templates/"+params.slug, params.data)
    },
    delete(params) {
      return ApiService.delete("certificate-templates",params);
    }, 

  }