import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";

import {
    LOGIN,
    LOGOUT,
    CHECK_AUTH
} from "./actions.type";
import { SET_AUTH, PURGE_AUTH, SET_AUTH_ERROR } from "./mutations.type";

const state = {
    autherrors: null,
    user: {},
    isAuthenticated: !!JwtService.getToken()
  };

  const getters = {
    currentUser(state) {
      return state.user;
    },
    isAuthenticated(state) {
      return state.isAuthenticated;
    }
  };

  const actions = {
    [LOGIN](context, credentials) {
      return new Promise(resolve => {
        ApiService.post("login", credentials)
          .then(({ data }) => {
            context.commit(SET_AUTH, data.data);
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_AUTH_ERROR, response.data.message);
          });
      });
    },
    [LOGOUT](context) {
      context.commit(PURGE_AUTH);
    },
    [CHECK_AUTH](context) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get("user")
          .then(({ data }) => {
            context.commit(SET_AUTH, data.user);
          })
          .catch(({ response }) => {
            if(response !=undefined)
            context.commit(SET_AUTH_ERROR, response.data.message);
          });
      } else {
        context.commit(PURGE_AUTH);
      }
    },
  };

  const mutations = {
    [SET_AUTH_ERROR](state, error) {
      state.autherrors = error;
    },
    [SET_AUTH](state, data) {
      state.isAuthenticated = true;
      state.user = data.user;
      state.autherrors = {};
      JwtService.saveToken(data.token);
    },
    [PURGE_AUTH](state) {
      state.isAuthenticated = false;
      state.user = {};
      state.autherrors = {};
      JwtService.destroyToken();
    }
  };

  export default {
    state,
    actions,
    mutations,
    getters
  };
  