import { TutorProductService } from "@/common/api.service";
import { FETCH_TUTOR_PRODUCTS, DELETE_TUTOR_PRODUCT,ADD_TUTOR_PRODUCT, UPDATE_TUTOR_PRODUCT,FETCH_INDIVIDUAL_TUTOR_PRODUCT,TUTOR_PRODUCT_RESET_STATE } from "./actions.type";
import { SET_TUTOR_PRODUCTS, SET_INDIVIDUAL_TUTOR_PRODUCT,SET_TUTOR_PRODUCT_ERROR,RESET_TUTOR_PRODUCTS_STATE } from "./mutations.type";

const initialState = () =>{
    return {
        headers: [
            { text: 'Tutor Product Name', value: 'tutor_product_name' },
            { text: 'Actions', value: 'action', sortable: false },
        ],     
        tutorproducts: [],
        individualtutorproduct:[],
        tutorproducterrors: null,
    }
};

export const state = initialState();

const getters = {
    tutorproducts(state) {
        return state.tutorproducts;
    },
    individualtutorproduct(state) {
        return state.individualtutorproduct;
    }    
};

const actions = {
    async [FETCH_TUTOR_PRODUCTS](context) {
        const { data } = await TutorProductService.get();
        context.commit(SET_TUTOR_PRODUCTS, data.data);
        return data;
    },   
    async [FETCH_INDIVIDUAL_TUTOR_PRODUCT](context,slug) {
        const { data } = await TutorProductService.get(slug);
        context.commit(SET_INDIVIDUAL_TUTOR_PRODUCT, data.data);
        return data;
    },       
    async [ADD_TUTOR_PRODUCT](context, payload) {
        return await TutorProductService.create(payload)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_TUTOR_PRODUCT_ERROR, error.response.data.errors);
        });
    },    
    async [UPDATE_TUTOR_PRODUCT](context, payload) {
        return await TutorProductService.update(payload)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_TUTOR_PRODUCT_ERROR, error.response.data.errors);
        });
    }, 
    async [DELETE_TUTOR_PRODUCT] (context, slug) {
        return await TutorProductService.delete(slug)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_TUTOR_PRODUCT_ERROR, error.response.data.errors);
        });
    },   
    [TUTOR_PRODUCT_RESET_STATE]({ commit }) {
        commit(RESET_TUTOR_PRODUCTS_STATE);
    }    
};

const mutations = {
    [SET_TUTOR_PRODUCT_ERROR](state, error) {
        state.tutorproducterrors = error;
    },      
    [SET_TUTOR_PRODUCTS](state, tutorproducts) {
        state.tutorproducts = tutorproducts;
    },
    [SET_INDIVIDUAL_TUTOR_PRODUCT](state, tutorproducts) {
        state.individualtutorproduct = tutorproducts;
    },    
    [RESET_TUTOR_PRODUCTS_STATE](state) {
        Object.assign(state, initialState())
    }    
};

export default {
    state,
    getters,
    actions,
    mutations
};