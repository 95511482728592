<template>
  <v-container fluid>
    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="blue darken-3" dark>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <v-app-bar-nav-icon @click.stop="mini = !mini" />
        <span class="hidden-sm-and-down">{{ 'LMS Admin'+' - '+version }}</span>
      </v-toolbar-title>
      <v-spacer />
      <v-menu offset-y bottom origin="center center" transition="scale-transition">
        <template v-slot:activator="{ on }">
          <v-btn icon large v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in dropdown" :key="item.text+index" @click="logout">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" :mini-variant.sync="mini" permanent :clipped="$vuetify.breakpoint.lgAndUp" app>
      <v-list dense>
        <template v-for="item in items">
          <v-list-group
            v-if="item.children"
            :key="item.text"
            v-model="item.model"
          >
            <template v-slot:activator>
              <v-list-item style="padding: 0px;">
                <v-list-item-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item v-for="(child, i) in item.children" :to="child.link" :key="child.text+i" link>
              <v-list-item-action v-if="child.icon">
                <v-icon small :style='(mini == false ? "margin-left: 20px;" : "")'>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ child.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.text" :to="item.link" link>
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
export default {
  name: "Navbar",
  props: {
    source: String,
    version: String
  },
  data: () => ({
    dialog: false,
    drawer: true,
    mini: true,
    items: [
      { icon: "mdi-home", text: "Dashboard", link: "/dashboard" },
      { icon: "mdi-school", text: "Tutor Products", link: "/tutor-products" },
      { icon: "mdi-view-module", text: "Courses", link: "/courses" },
      { icon: "mdi-book", text: "Modules", link: "/modules" },
      { icon: "mdi-city", text: "Company", link: "/company" },
      {
        icon: 'mdi-human',
        text: 'Entities',
        model: false,
        children: [
          { icon: "mdi-account-box", text: "Authors", link: "/authors" },
          { icon: "mdi-account-multiple", text: "Teams", link: "/teams" },
        ],
      },

      { icon: "mdi-page-layout-body", text: "Pages", link: "/pages" },
      { icon: "mdi-file-video", text: "Video tutorial", link: "/video-tutorial" },
      { icon: "mdi-cash", text: "Payments", link: "/payments" },
      {
        icon: 'mdi-file-document',
        text: 'Documents',
        model: false,
        children: [
          { icon:'mdi-library', text: 'General documents', link: '/general-documents' },
        ],
      },

    ],
    dropdown: [{ icon: "mdi-power", text: "Logout" }]
  }),
    methods: {
      logout: function () {
        this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/login')
        })
      }
    },   
};
</script>