import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import auth from './auth.module'
import modules from './modules.module'
import authors from "./authors.module"
import stripe from "./stripe.module"
import globals from "./globals.module"
import company from "./company.module"
import pages from "./pages.module"
import languages from "./languages.module"
import documents from "./documents.module"
import courses from './courses.module'
import tutorproducts from './tutorproducts.module'
import videotutorial from './tutorialvideos.module'
import sectionheaders from './sectionheaders.module'
import certificationtemplates from './certificatetemplate.module'
import teams from "./teams.module"

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    auth,
    authors,
    stripe,
    pages,
    sectionheaders,
    videotutorial,
    globals,
    company,
    courses,
    tutorproducts,
    modules,
    documents,
    languages,
    certificationtemplates,
    teams
  }
})