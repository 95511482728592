import { CompaniesService } from "@/common/api.service";
import { FETCH_COMPANIES, FETCH_INDIVIDUAL_COMPANY,ADD_COMPANY, UPDATE_COMPANY ,COMPANIES_RESET_STATE } from "./actions.type";
import { SET_COMPANIES, SET_INDIVIDUAL_COMPANY ,SET_COMPANY_ERROR,RESET_COMPANIES_STATE } from "./mutations.type";

const initialState = () =>{
    return {
        companyHeaders: [
            { text: 'Company Prefix', value: 'company_prefix'},
            { text: 'Company Name', value: 'company_name' },
            { text: 'Account Type', value: 'account_type' },
            { text: 'Available Seats', value: 'available_seats' },
            { text: 'Status', value: 'company_status' },
            { text: 'Actions', value: 'action', sortable: false },
        ],  
        companies: [],
        individualcompany: [],
        companyerrors: null,
    }
} ;

export const state = initialState();

const getters = {
    companies(state) {
        return state.companies;
    },
    individualcompany(state) {
        return state.individualcompany;
    }
};

const actions = {
    async [FETCH_COMPANIES](context, companySlug) {
        const { data } = await CompaniesService.get(companySlug);
        context.commit(SET_COMPANIES, data.data);
        return data;
    },
    async [FETCH_INDIVIDUAL_COMPANY](context, companyid) {
        const { data } = await CompaniesService.get(companyid);
        context.commit(SET_INDIVIDUAL_COMPANY, data.data);
        return data;        
    },    
    async [ADD_COMPANY](context, payload) {
        return await CompaniesService.create(payload)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_COMPANY_ERROR, error.response.data.errors);
        });
    },   
    async [UPDATE_COMPANY](context, payload) {
        return await CompaniesService.update(payload)
        .then(({ data }) => {
          return data
        })
        .catch(error => {
          context.commit(SET_COMPANY_ERROR, error.response.data.errors);
        });
    },      
    [COMPANIES_RESET_STATE]({ commit }) {
        commit(RESET_COMPANIES_STATE);
    }
};

const mutations = {
    [SET_COMPANY_ERROR](state, error) {
        state.companyerrors = error;
      },  
    [SET_COMPANIES](state, companies) {
        state.companies = companies;
    },
    [SET_INDIVIDUAL_COMPANY](state, company) {
        state.individualcompany = company;
    },    
    [RESET_COMPANIES_STATE](state) {
        Object.assign(state, initialState())
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};