import Vue from 'vue'
import Company from '../views/company/company.vue'
import VueRouter from 'vue-router'
import { CHECK_AUTH } from "@/store/actions.type"
import store from "@/store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },  
  {
    path: '/',
    name: 'dashboard',
    component: () => import('../views/dashboard/dashboard.vue'),   
  },  
  {
    path: "/login",
    name: "login",
    component: () => import("../views/authentication/login"),    
  },   
  {
    path: "/modules",
    name: "modules",
    component: () => import("../views/modules/modules"),
    meta: { 
      requiresAuth: true
    }    
  },   
  {
    path: "/modules/add",
    name: "AddModules",
    component: () => import("../views/modules/addeditModules"),
    meta: { 
      requiresAuth: true
    }    
  }, 
  {
    path: "/modules/edit/:id",
    name: "editmodules",
    component: () => import("../views/modules/addeditModules"),
    meta: { 
      requiresAuth: true
    }    
  },    
  {
    path: "/company",
    name: "company",
    component: Company,
    meta: { 
      requiresAuth: true
    }    
  },   
  {
    path: "/general-documents",
    name: "generaldocuments",
    component: () => import("../views/documents/general/Generaldocuments"),   
  },
  {
    path: "/general-documents/add",
    name: "addgeneraldocuments",
    component: () => import("../views/documents/general/addeditGeneraldocuments"),   
  },    
  {
    path: "/general-documents/edit/:id",
    name: "editgeneraldocuments",
    component: () => import("../views/documents/general/addeditGeneraldocuments"),   
  },     
  {
    path: "/authors",
    name: "authors",
    component: () => import("../views/authors/authors"),
    meta: { 
      requiresAuth: true
    }    
  }, 
  {
    path: "/authors/edit/:id",
    name: "editauthors",
    component: () => import("../views/authors/addeditAuthors"),
    meta: { 
      requiresAuth: true
    }    
  },  
  {
    path: "/authors/add/",
    name: "addauthors",
    component: () => import("../views/authors/addeditAuthors"),
    meta: { 
      requiresAuth: true
    }    
  },     
  {
    path: "/teams",
    name: "teams",
    component: () => import("../views/teams/teams"),
    meta: { 
      requiresAuth: true
    }    
  }, 
  {
    path: "/teams/edit/:id",
    name: "editteams",
    component: () => import("../views/teams/addeditteams"),
    meta: { 
      requiresAuth: true
    }    
  },  
  {
    path: "/teams/add/",
    name: "addteams",
    component: () => import("../views/teams/addeditteams"),
    meta: { 
      requiresAuth: true
    }    
  },  
  {
    path: "/tutor-products",
    name: "tutorproducts",
    component: () => import("../views/tutorProducts/tutorproducts"),
    meta: { 
      requiresAuth: true
    }    
  }, 
  {
    path: "/tutor-products/edit/:id",
    name: "edittutorproducts",
    component: () => import("../views/tutorProducts/addedittutorproducts"),
    meta: { 
      requiresAuth: true
    }    
  },  
  {
    path: "/tutor-products/add/",
    name: "addtutorproducts",
    component: () => import("../views/tutorProducts/addedittutorproducts"),
    meta: { 
      requiresAuth: true
    }    
  },      
  {
    path: "/payments",
    name: "payments",
    component: () => import("../views/payments/payments"),
    meta: { 
      requiresAuth: true
    }    
  },    
  {
    path: "/company/add",
    name: "addcompany",
    component: () => import("../views/company/addeditCompany"),
    meta: { 
      requiresAuth: true
    }    
  },    
  {
    path: "/company/edit/:id",
    name: "editcompany",
    component: () => import("../views/company/addeditCompany"),
    meta: { 
      requiresAuth: true
    }    
  },  
  {
    path: "/courses",
    name: "courses",
    component: () => import("../views/courses/courses"),    
  },    
  {
    path: "/courses/add",
    name: "addcourses",
    component: () => import("../views/courses/addeditCourses"),    
  }, 
  {
    path: "/courses/edit/:id",
    name: "editcourses",
    component: () => import("../views/courses/addeditCourses"),    
  },   
  {
    path: "/pages",
    name: "pages",
    component: () => import("../views/pages/pages"),    
  },   
  {
    path: "/pages/add",
    name: "addpage",
    component: () => import("../views/pages/addeditPages"),    
  },  
  {
    path: "/pages/edit/:id",
    name: "editpage",
    component: () => import("../views/pages/addeditPages"),    
  },    
  {
    path: "/video-tutorial",
    name: "videotutorial",
    component: () => import("../views/videotutorial/videotutorial"),    
  }, 
  {
    path: "/video-tutorial/add",
    name: "addVideoTutorial",
    component: () => import("../views/videotutorial/addeditVideoTutorial"),    
  },   
  
  {
    path: "/video-tutorial/edit/:id",
    name: "editVideoTutorial",
    component: () => import("../views/videotutorial/addeditVideoTutorial"),    
  },   
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next) =>
  store.dispatch(CHECK_AUTH).then(() =>{
    if(!['login'].includes(to.name) && !store.getters.isAuthenticated){
      next({name: 'login'})
    } else next()
  }) 
);

export default router
